$card-empty-msg-color: color('base-2') !default;

.phone-select-country {
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.btn.m-remove {
    position: absolute;
    top: 8px;
    width: 16px;
    height: 16px;
    right: 8px;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    opacity: 0.5;
    z-index: z('default');

    @include media-breakpoint-up(md) {
        top: 50%;
        transform: translateY(-50%);
    }

    &::after {
        @include inline-svg-bg($icon-close);

        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    @include on-event {
        opacity: 1;
    }
}

.ma-container {
    .card {
        &.m-indented {
            margin-top: 50px;

            @include media-breakpoint-up(md) {
                margin-top: 90px;
            }
        }

        &-body {
            dd,
            dl:last-child {
                margin-bottom: 0;
            }
        }

        &-empty-msg {
            padding: 35px 0;
            color: $card-empty-msg-color;
        }
    }

    .nosubscribe-text {
        color: $card-empty-msg-color;
    }
}

.back-to-account-link {
    padding-top: 10px;
    padding-bottom: 50px;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: right;
    }
}
