.order-billing {
    margin-bottom: 26px;

    &-title {
        color: color('base-4');
    }

    &-non-adjusted-price {
        margin-right: 0;
    }
}
