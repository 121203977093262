$passenger-details-border-color: #f2f1f1 !default;
$passenger-cancel-btn-color: color('base-5') !default;

.passenger {
    &-details {
        border-top: 1px solid $passenger-details-border-color;
        padding: 20px 18px;

        @include media-breakpoint-up(lg) {
            padding: 20px;
        }

        &-line {
            @include media-breakpoint-down(md) {
                display: flex;
            }
        }

        &-label {
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(md) {
                width: 75px;
                margin-right: 75px;
            }
        }

        &-info {
            word-break: break-all;
        }
    }

    &-comments {
        margin-top: 20px;
    }

    &-bday-select {
        margin-top: 27px;
    }

    &-form {
        .btn-cancel {
            @include link-defaults($passenger-cancel-btn-color, underline, $passenger-cancel-btn-color, none);
            @include link-button;
        }
    }
}

.passengers {
    &-info {
        padding-top: 0;
        padding-bottom: 0;
    }

    &-title {
        @include media-breakpoint-up(lg) {
            padding: 15px 20px;
        }

        @include media-breakpoint-down(md) {
            + .passenger-details {
                border-top: none;
            }
        }
    }

    &-list {
        &-item-details {
            width: 100%;
        }

        &-details-label {
            color: color('base-5');
            width: 75px;
            margin-right: 75px;
        }

        &-details-info {
            word-break: break-all;
        }
    }
}
