$services-item-brand-color: color('base-4') !default;
$services-item-name-color: color('base-0') !default;
$services-item-price-color: $black !default;
$services-item-old-price-color: color('base-2') !default;
$services-item-error-border-color: color('secondary-5') !default;
$services-item-error-message-color: color('secondary-4') !default;
$services-item-order-details-item-min-width: 86px !default;

.services {
    @include media-breakpoint-up(lg) {
        padding: 0 40px;
    }

    &.m-checkout {
        padding: 0;
    }

    &-quantity {
        display: flex;
    }

    &-title {
        display: flex;
        font-size: 16px;
        padding: 20px 0 0;

        @include media-breakpoint-up(lg) {
            justify-content: space-between;
            padding: 40px 0 0;
        }

        @include when-inside('.m-checkout') {
            font-size: 24px;
            margin-bottom: 0;
            padding-top: 20px;
        }

        &-totalprice {
            min-width: $services-item-order-details-item-min-width;
        }

        + .services-item {
            margin-top: 20px;
        }
    }
}

.services-item { // stylelint-disable-line
    border-top: 1px solid $border-color;
    padding: 30px 0;

    @include when-inside('.m-checkout') {
        padding: 20px 0;
    }

    &.m-unavailable {
        border-color: $services-item-error-border-color;

        + .services-item {
            border-color: $services-item-error-border-color;
        }
    }

    &:first-child { // stylelint-disable-line
        @include when-inside('.m-checkout') {
            border-top: none;
        }
    }

    &:last-child { // stylelint-disable-line
        &.m-unavailable {
            border-bottom: 1px solid $services-item-error-border-color;
        }
    }

    &-attributes {
        position: relative;
        flex: 1;
    }

    &-header {
        margin-bottom: 20px;

        @include when-inside('.m-checkout') {
            margin-bottom: 25px;
        }

        &-desktop {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &-mobile {
            @include media-breakpoint-up(lg) {
                display: none;
            }

            .services-item-brand {
                display: none;
            }
        }
    }

    &-brand {
        color: $services-item-brand-color;
        margin-bottom: 12px;

        @include when-inside('.m-checkout') {
            margin-bottom: 6px;
        }

        @include when-inside('.theme-marhaba') {
            text-transform: lowercase;
        }
    }

    &-name {
        color: $services-item-name-color;
        font-size: 16px;
        margin-bottom: 26px;
        text-decoration: underline;

        @include when-inside('.m-checkout') {
            font-size: 18px;
        }
    }

    &-link-more {
        align-self: flex-end;
        margin-bottom: 10px;
        margin-left: 10px;
        white-space: nowrap;
    }

    &-mobile-price-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 8px;
        line-height: 20px;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &-price {
        color: $services-item-price-color;
        flex: 1;
        font-size: 18px;
        font-weight: $font-weight-medium;
        margin-bottom: 8px;
        text-align: right;

        @include media-breakpoint-up(lg) {
            margin-right: 0;

            @include when-inside('.m-unavailable') {
                opacity: 0.5;
            }
        }

        .order-billing-price {
            display: inline-block;
            min-width: $services-item-order-details-item-min-width;
            text-align: left;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
                margin-left: 15px;
                text-align: right;
            }

            @include when-inside('.m-checkout') {
                margin-left: 0;
            }
        }

        .order-billing-non-adjusted-price {
            color: $services-item-old-price-color;
            margin-right: 15px;
            text-decoration: line-through;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
                margin-right: 0;
            }

            @include when-inside('.m-checkout') {
                margin-right: 15px;
            }
        }
    }

    &-price-total {
        display: flex;
        flex-flow: row-reverse;
        justify-content: flex-end;

        @include media-breakpoint-up(lg) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        @include when-inside('.m-checkout') {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }

    &-image {
        margin-bottom: 25px;
        margin-right: 20px;
        width: 100px;
    }

    &-passengers {
        margin-bottom: 8px;

        @include when-inside('.m-checkout') {
            margin-bottom: 0;
        }
    }

    &-edit-passengers {
        margin-bottom: 18px;
        display: none;

        .m-cart & {
            display: block;
        }

        .m-checkout &,
        .order-confirmation & {
            display: none;
        }

        &-actions {
            margin: 0 -4px;

            @include media-breakpoint-up(lg) {
                position: relative;
                right: -84px;
                top: 0px;
                justify-content: right;
            }

            @include media-breakpoint-down(lg) {
                justify-content: space-between;
                margin-top: 16px;
            }
        }

        &-action {
            padding: 0 4px;

            @include media-breakpoint-down(lg) {
                flex-grow: 1;
            }
        }
    }

    &-edit-passengers-block {
        margin: 24px 12px;

        .passengerscountblock-init {
            @include media-breakpoint-down(md) {
                justify-content: space-around;
            }
        }

        .quantity {
            @include media-breakpoint-up(lg) {
                margin-right: 10%;
            }
        }

        .chauffeur-service {
            .quantity {
                .quantity-label-sub {
                    padding-right: initial;
                }

                &-label {
                    span {
                        line-height: 1.2;
                        display: block;
                    }
                }
            
                margin-right: 0;                                       
                width: calc(25% - 10px);

                &:not(:last-child) {
                    padding-right: 10px;
                }                
            }
        }
    }

    &-service {
        margin-bottom: 10px;
    }

    &-additional-services {
        padding-bottom: 0;

        li hr {
            display: none;
        }

        li + li hr {
            display: block;
            width: 115%;

            @include when-inside('.m-checkout') {
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    &-actions {
        @include media-breakpoint-down(md) {
            margin: 20px 0;
        }

        @include media-breakpoint-up(lg) {
            &.hidden {
                display: block !important;
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &-edit {
        @include media-breakpoint-down(md) {
            min-width: 120px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
            margin-top: 10px;
        }
    }

    &-message {
        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }

        @include media-breakpoint-up(lg) {
            @include when-inside('.m-unavailable') {
                opacity: 0.5;
            }
        }

        @include when-inside('.m-checkout') {
            margin-top: 15px;
        }
    }

    &-info {
        flex: 1;

        @include when-inside('.m-unavailable') {
            opacity: 0.5;
        }
    }

    &-info-top {
        @include media-breakpoint-down(lg) {
            display: flex;
            flex-flow: row nowrap;
        }
    }

    &-booking {
        font-size: 16px;
    }

    &-booking-label {
        color: color('base-4');
    }

    &-error {
        color: $services-item-error-message-color;
        display: none;
        margin-bottom: 20px;

        @include when-inside('.m-unavailable') {
            display: block;
        }
    }

    &-included {
        padding-left: 0;

        &::before {
            content: none;
        }
    }

    &-chauffeur {
        margin-right: 50px;
    }

    &-chauffeur-info {
        margin-left: -30px;
    }

    &-chauffeur-price {
        position: absolute;
        right: -80px;
        top: 0;

        @include media-breakpoint-down(md) {
            position: static;
            right: 0;
            margin-left: -30px;
        }

        @include when-inside('.m-checkout') {
            position: static;
            right: 0;
            margin-left: -30px;
        }
    }
}

.opposite-service-button {
    margin-top: 45px;
}
