.card-number-wrapper {
    position: relative;

    &::after {
        content: '';
        background-repeat: no-repeat;
        background-image: url('../images/payment-types-new.png');
        background-size: auto;
        background-position: 0 -58px;
        width: 52px;
        height: 29px;
        position: absolute;
        right: 4px;
        top: 5px;
    }

    &[data-type="visa"]::after {
        background-position: -52px -29px;
    }

    &[data-type="mastercard"]::after {
        background-position: 0 0;
    }

    &[data-type="amex"]::after {
        background-position: 0 -29px;
    }

    &[data-type="diners"]::after {
        background-position: -52px 0;
    }
}
