// -----------------------------------------------------------------------------
// This file contains all colors related to the Marhaba theme
// -----------------------------------------------------------------------------

// Colors
//
// Markup:
// <div class="kss-color-item"><span style="background: #2d2423;"></span>#2d2423<br>$dark<br>color('base-0')</div>
// <div class="kss-color-item"><span style="background: #d4d2d0;"></span>#d4d2d0<br>$grey-1<br>color('base-1')</div>
// <div class="kss-color-item"><span style="background: #cac0b6;"></span>#cac0b6<br>$grey-2<br>color('base-2')</div>
// <div class="kss-color-item"><span style="background: #afa18f;"></span>#afa18f<br>$grey-3<br>color('base-3')</div>
// <div class="kss-color-item"><span style="background: #9f9593;"></span>#9f9593<br>$grey-4<br>color('base-4')</div>
// <div class="kss-color-item"><span style="background: #766a65;"></span>#766a65<br>$grey-5<br>color('base-5')</div>
// <div class="kss-color-item"><span style="background: #e60053;"></span>#e60053<br>$pink<br>color('primary-1')</div>
// <div class="kss-color-item"><span style="background: #830051;"></span>#830051<br>$violet<br>color('primary-2')</div>
// <div class="kss-color-item"><span style="background: #329b32;"></span>#329b32<br>$green<br>color('secondary-0')</div>
// <div class="kss-color-item"><span style="background: #eeaf00;"></span>#eeaf00<br>$yellow-1<br>color('secondary-1')</div>
// <div class="kss-color-item"><span style="background: #f59a00;"></span>#f59a00<br>$yellow-2<br>color('secondary-2')</div>
// <div class="kss-color-item"><span style="background: #de684b;"></span>#de684b<br>$orange-1<br>color('secondary-3')</div>
// <div class="kss-color-item"><span style="background: #de422d;"></span>#de422d<br>$orange-2<br>color('secondary-4')</div>
// <div class="kss-color-item"><span style="background: #b00837;"></span>#b00837<br>$pink-hover<br>color('secondary-5')</div>
// <div class="kss-color-item"><span style="background: #5e0034;"></span>#5e0034<br>$violet-hover<br>color('secondary-6')</div>
// <div class="kss-color-item"><span style="background: #000;"></span>#000<br>$black</div>
// <div class="kss-color-item"><span style="background: #fff;"></span>#fff<br>$white</div>
// <style type="text/css">
// .kss-color-item {display: inline-block; vertical-align: top; padding: 0 0 15px; width: 33%; text-align: center;}
// .kss-color-item span {display: block; margin: 0 0 5px; height: 80px; border: 1px solid #333;}
// </style>
//
// Styleguide 2

// Base Colors
$grey-1:       #d4d2d0;
$grey-2:       #cac0b6;
$grey-3:       #afa18f;
$grey-4:       #9f9593;
$grey-5:       #766a65;
$grey-6:       #f2f1f1;
$grey-7:       #f6f6f6;
$grey-8:       #666;
$dark:         #2d2423;
$dark-pdx:     #444;

// Primary Colors
$pink:         #e60053;
$violet:       #830051;

// Secondary Colors
$green:        #329b32;
$yellow-1:     #eeaf00;
$yellow-2:     #f59a00;
$orange-1:     #de684b;
$orange-2:     #de422d;
$orange-3:     #F15E4D;
$pink-hover:   #b00837;
$violet-hover: #5e0034;

$colors: (
    'base-0':      $dark,
    'base-1':      $grey-1,
    'base-2':      $grey-2,
    'base-3':      $grey-3,
    'base-4':      $grey-4,
    'base-5':      $grey-5,
    'base-6':      $grey-6,
    'base-7':      $grey-7,
    'base-8':      $grey-8,
    'primary-1':   $pink,
    'primary-2':   $violet,
    'secondary-0': $green,
    'secondary-1': $yellow-1,
    'secondary-2': $yellow-2,
    'secondary-3': $orange-1,
    'secondary-4': $orange-2,
    'secondary-5': $pink-hover,
    'secondary-6': $violet-hover,
    'secondary-7': $orange-3,
    'dark-pdx':    $dark-pdx
);
