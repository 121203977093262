.wishlist {
    &-card {
        padding: 16px 0;
        position: relative;
        border-bottom: 1px solid $hr-border-color;

        &:first-child {
            padding-top: 0;
        }

        &:nth-last-child(2) {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    &-hide {
        margin: 16px 0 0;

        &-top {
            margin-bottom: 0;
        }
    }

    &-footer {
        padding: 16px 0;
    }

    .price-value {
        font-size: 26px;

        @include media-breakpoint-up(sm) {
            font-size: 22px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 26px;
        }
    }

    .product-price {
        @include media-breakpoint-up(sm) {
            flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        &-currency.m-through {
            @include media-breakpoint-up(sm) {
                margin: 4px 0 0;
            }

            @include media-breakpoint-up(lg) {
                margin: 0 0 0 16px;
            }
        }
    }
}
