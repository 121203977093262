/* $headings-font-family:        inherit !default; */
/* md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
    sm: 'screen and (max-width: 767px)',
    md: 'screen and (min-width: 768px) and (max-width: 1199px)',
    lg: 'screen and (min-width: 1200px)',
    xl: 'screen and (min-width: 1201px)',
    md-up: 'screen and (min-width: 768px)',
    md-down: 'screen and (max-width: 1023px)',
    lg-up: 'screen and (min-width: 1024px)',
    lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
    // styles outside of a media query

    @include media(md-up) {
        // styles for "m" and "l" viewports
    };

    @include media(sm) {
        // styles for "s" viewports
    };
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
.text-justify {
	text-align: justify !important;
}

@media (min-width: 568px) {
	.text-sm-justify {
		text-align: justify !important;
	}
}
@media (min-width: 768px) {
	.text-md-justify {
		text-align: justify !important;
	}
}
@media (min-width: 1024px) {
	.text-lg-justify {
		text-align: justify !important;
	}
}
@media (min-width: 1240px) {
	.text-lgm-justify {
		text-align: justify !important;
	}
}
@media (min-width: 1465px) {
	.text-xl-justify {
		text-align: justify !important;
	}
}
@keyframes fade-in {
	from {
		opacity: 0;
		visibility: hidden;
	}
	to {
		opacity: 1;
		visibility: visible;
	}
}
.ma-container {
	padding-top: 50px;
	padding-bottom: 60px;
}
@media (min-width: 768px) {
	.ma-container {
		padding-top: 20px;
		padding-bottom: 100px;
	}
}
.ma-container .track-order-header {
	color: #2d2423;
}
.ma-container .card-body-positioning {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
}
.ma-container .breadcrumbs {
	margin-bottom: 0;
}

@media (pointer: coarse) {
	.ma-download-data {
		display: none;
	}
}

.login-page .tabs-nav-list {
	margin-bottom: 20px;
}
.login-page .create-account-button + .content-asset {
	margin-top: 20px;
}

.phone-select-country {
	margin-bottom: 16px;
}
@media (min-width: 1024px) {
	.phone-select-country {
		margin-bottom: 0;
	}
}

.btn.m-remove {
	position: absolute;
	top: 8px;
	width: 16px;
	height: 16px;
	right: 8px;
	padding: 0;
	background-color: transparent;
	border: none;
	outline: none;
	opacity: 0.5;
	z-index: 1;
}
@media (min-width: 768px) {
	.btn.m-remove {
		top: 50%;
		transform: translateY(-50%);
	}
}
.btn.m-remove::after {
	background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath fill='%23766a65' d='M18.475 16 29.487 4.987a1.75 1.75 0 1 0-2.474-2.474L16 13.525 4.987 2.513a1.75 1.75 0 1 0-2.474 2.474L13.525 16 2.513 27.013a1.75 1.75 0 1 0 2.474 2.474L16 18.475l11.013 11.012c.341.342.79.513 1.237.513a1.75 1.75 0 0 0 1.237-2.987z'/%3E%3C/svg%3E") no-repeat 50% 50%;
	background-size: contain;
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.btn.m-remove:hover, .btn.m-remove:active {
	opacity: 1;
}

.ma-container .card.m-indented {
	margin-top: 50px;
}
@media (min-width: 768px) {
	.ma-container .card.m-indented {
		margin-top: 90px;
	}
}
.ma-container .card-body dd,
.ma-container .card-body dl:last-child {
	margin-bottom: 0;
}
.ma-container .card-empty-msg {
	padding: 35px 0;
	color: #cac0b6;
}
.ma-container .nosubscribe-text {
	color: #cac0b6;
}

.back-to-account-link {
	padding-top: 10px;
	padding-bottom: 50px;
	text-align: center;
}
@media (min-width: 768px) {
	.back-to-account-link {
		text-align: right;
	}
}

.wishlist-card {
	padding: 16px 0;
	position: relative;
	border-bottom: 1px solid #f2f1f1;
}
.wishlist-card:first-child {
	padding-top: 0;
}
.wishlist-card:nth-last-child(2) {
	padding-bottom: 0;
	border-bottom: none;
}
.wishlist-hide {
	margin: 16px 0 0;
}
.wishlist-hide-top {
	margin-bottom: 0;
}
.wishlist-footer {
	padding: 16px 0;
}
.wishlist .price-value {
	font-size: 26px;
}
@media (min-width: 568px) {
	.wishlist .price-value {
		font-size: 22px;
	}
}
@media (min-width: 1024px) {
	.wishlist .price-value {
		font-size: 26px;
	}
}
@media (min-width: 568px) {
	.wishlist .product-price {
		flex-direction: column;
	}
}
@media (min-width: 1024px) {
	.wishlist .product-price {
		flex-direction: row;
	}
}
@media (min-width: 568px) {
	.wishlist .product-price-currency.m-through {
		margin: 4px 0 0;
	}
}
@media (min-width: 1024px) {
	.wishlist .product-price-currency.m-through {
		margin: 0 0 0 16px;
	}
}

.passenger-details {
	border-top: 1px solid #f2f1f1;
	padding: 20px 18px;
}
@media (min-width: 1024px) {
	.passenger-details {
		padding: 20px;
	}
}
@media (max-width: 1023.98px) {
	.passenger-details-line {
		display: flex;
	}
}
.passenger-details-label {
	font-weight: 700;
}
@media (max-width: 1023.98px) {
	.passenger-details-label {
		width: 75px;
		margin-right: 75px;
	}
}
.passenger-details-info {
	word-break: break-all;
}
.passenger-comments {
	margin-top: 20px;
}
.passenger-bday-select {
	margin-top: 27px;
}
.passenger-form .btn-cancel {
	color: #766a65;
	text-decoration: underline;
	appearance: none;
	background: none;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
}
.passenger-form .btn-cancel:hover {
	color: #766a65;
	text-decoration: none;
}

.passengers-info {
	padding-top: 0;
	padding-bottom: 0;
}
@media (min-width: 1024px) {
	.passengers-title {
		padding: 15px 20px;
	}
}
@media (max-width: 1023.98px) {
	.passengers-title + .passenger-details {
		border-top: none;
	}
}
.passengers-list-item-details {
	width: 100%;
}
.passengers-list-details-label {
	color: #766a65;
	width: 75px;
	margin-right: 75px;
}
.passengers-list-details-info {
	word-break: break-all;
}

.card-number-wrapper {
	position: relative;
}
.card-number-wrapper::after {
	content: "";
	background-repeat: no-repeat;
	background-image: url("../images/payment-types-new.png");
	background-size: auto;
	background-position: 0 -58px;
	width: 52px;
	height: 29px;
	position: absolute;
	right: 4px;
	top: 5px;
}
.card-number-wrapper[data-type=visa]::after {
	background-position: -52px -29px;
}
.card-number-wrapper[data-type=mastercard]::after {
	background-position: 0 0;
}
.card-number-wrapper[data-type=amex]::after {
	background-position: 0 -29px;
}
.card-number-wrapper[data-type=diners]::after {
	background-position: -52px 0;
}

@media (min-width: 1024px) {
	.services {
		padding: 0 40px;
	}
}
.services.m-checkout {
	padding: 0;
}
.services-quantity {
	display: flex;
}
.services-title {
	display: flex;
	font-size: 16px;
	padding: 20px 0 0;
}
@media (min-width: 1024px) {
	.services-title {
		justify-content: space-between;
		padding: 40px 0 0;
	}
}
.m-checkout .services-title {
	font-size: 24px;
	margin-bottom: 0;
	padding-top: 20px;
}
.services-title-totalprice {
	min-width: 86px;
}
.services-title + .services-item {
	margin-top: 20px;
}

.services-item {
	border-top: 1px solid #dee2e6;
	padding: 30px 0;
}
.m-checkout .services-item {
	padding: 20px 0;
}
.services-item.m-unavailable {
	border-color: #b00837;
}
.services-item.m-unavailable + .services-item {
	border-color: #b00837;
}
.m-checkout .services-item:first-child {
	border-top: none;
}
.services-item:last-child.m-unavailable {
	border-bottom: 1px solid #b00837;
}
.services-item-attributes {
	position: relative;
	flex: 1;
}
.services-item-header {
	margin-bottom: 20px;
}
.m-checkout .services-item-header {
	margin-bottom: 25px;
}
@media (max-width: 1023.98px) {
	.services-item-header-desktop {
		display: none;
	}
}
@media (min-width: 1024px) {
	.services-item-header-mobile {
		display: none;
	}
}
.services-item-header-mobile .services-item-brand {
	display: none;
}
.services-item-brand {
	color: #9f9593;
	margin-bottom: 12px;
}
.m-checkout .services-item-brand {
	margin-bottom: 6px;
}
.theme-marhaba .services-item-brand {
	text-transform: lowercase;
}
.services-item-name {
	color: #2d2423;
	font-size: 16px;
	margin-bottom: 26px;
	text-decoration: underline;
}
.m-checkout .services-item-name {
	font-size: 18px;
}
.services-item-link-more {
	align-self: flex-end;
	margin-bottom: 10px;
	margin-left: 10px;
	white-space: nowrap;
}
.services-item-mobile-price-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin-top: 8px;
	line-height: 20px;
}
@media (min-width: 1024px) {
	.services-item-mobile-price-wrap {
		display: none;
	}
}
.services-item-price {
	color: #000;
	flex: 1;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 8px;
	text-align: right;
}
@media (min-width: 1024px) {
	.services-item-price {
		margin-right: 0;
	}
	.m-unavailable .services-item-price {
		opacity: 0.5;
	}
}
.services-item-price .order-billing-price {
	display: inline-block;
	min-width: 86px;
	text-align: left;
	white-space: nowrap;
}
@media (min-width: 1024px) {
	.services-item-price .order-billing-price {
		margin-left: 15px;
		text-align: right;
	}
}
.m-checkout .services-item-price .order-billing-price {
	margin-left: 0;
}
.services-item-price .order-billing-non-adjusted-price {
	color: #cac0b6;
	margin-right: 15px;
	text-decoration: line-through;
	white-space: nowrap;
}
@media (min-width: 1024px) {
	.services-item-price .order-billing-non-adjusted-price {
		margin-right: 0;
	}
}
.m-checkout .services-item-price .order-billing-non-adjusted-price {
	margin-right: 15px;
}
.services-item-price-total {
	display: flex;
	flex-flow: row-reverse;
	justify-content: flex-end;
}
@media (min-width: 1024px) {
	.services-item-price-total {
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}
.m-checkout .services-item-price-total {
	flex-wrap: wrap;
	justify-content: flex-end;
}
.services-item-image {
	margin-bottom: 25px;
	margin-right: 20px;
	width: 100px;
}
.services-item-passengers {
	margin-bottom: 8px;
}
.m-checkout .services-item-passengers {
	margin-bottom: 0;
}
.services-item-edit-passengers {
	margin-bottom: 18px;
	display: none;
}
.m-cart .services-item-edit-passengers {
	display: block;
}
.m-checkout .services-item-edit-passengers, .order-confirmation .services-item-edit-passengers {
	display: none;
}
.services-item-edit-passengers-actions {
	margin: 0 -4px;
}
@media (min-width: 1024px) {
	.services-item-edit-passengers-actions {
		position: relative;
		right: -84px;
		top: 0px;
		justify-content: right;
	}
}
@media (max-width: 1239.98px) {
	.services-item-edit-passengers-actions {
		justify-content: space-between;
		margin-top: 16px;
	}
}
.services-item-edit-passengers-action {
	padding: 0 4px;
}
@media (max-width: 1239.98px) {
	.services-item-edit-passengers-action {
		flex-grow: 1;
	}
}
.services-item-edit-passengers-block {
	margin: 24px 12px;
}
@media (max-width: 1023.98px) {
	.services-item-edit-passengers-block .passengerscountblock-init {
		justify-content: space-around;
	}
}
@media (min-width: 1024px) {
	.services-item-edit-passengers-block .quantity {
		margin-right: 10%;
	}
}
.services-item-edit-passengers-block .chauffeur-service .quantity {
	margin-right: 0;
	width: calc(25% - 10px);
}
.services-item-edit-passengers-block .chauffeur-service .quantity .quantity-label-sub {
	padding-right: initial;
}
.services-item-edit-passengers-block .chauffeur-service .quantity-label span {
	line-height: 1.2;
	display: block;
}
.services-item-edit-passengers-block .chauffeur-service .quantity:not(:last-child) {
	padding-right: 10px;
}
.services-item-service {
	margin-bottom: 10px;
}
.services-item-additional-services {
	padding-bottom: 0;
}
.services-item-additional-services li hr {
	display: none;
}
.services-item-additional-services li + li hr {
	display: block;
	width: 115%;
}
.m-checkout .services-item-additional-services li + li hr {
	width: 100%;
}
@media (max-width: 1023.98px) {
	.services-item-additional-services li + li hr {
		width: 100%;
	}
}
@media (max-width: 1023.98px) {
	.services-item-actions {
		margin: 20px 0;
	}
}
@media (min-width: 1024px) {
	.services-item-actions.hidden {
		display: block !important;
		opacity: 0;
		visibility: hidden;
	}
}
@media (max-width: 1023.98px) {
	.services-item-edit {
		min-width: 120px;
	}
}
@media (min-width: 1024px) {
	.services-item-edit {
		margin-bottom: 20px;
		margin-top: 10px;
	}
}
@media (max-width: 1023.98px) {
	.services-item-message {
		margin-bottom: 10px;
	}
}
@media (min-width: 1024px) {
	.m-unavailable .services-item-message {
		opacity: 0.5;
	}
}
.m-checkout .services-item-message {
	margin-top: 15px;
}
.services-item-info {
	flex: 1;
}
.m-unavailable .services-item-info {
	opacity: 0.5;
}
@media (max-width: 1239.98px) {
	.services-item-info-top {
		display: flex;
		flex-flow: row nowrap;
	}
}
.services-item-booking {
	font-size: 16px;
}
.services-item-booking-label {
	color: #9f9593;
}
.services-item-error {
	color: #de422d;
	display: none;
	margin-bottom: 20px;
}
.m-unavailable .services-item-error {
	display: block;
}
.services-item-included {
	padding-left: 0;
}
.services-item-included::before {
	content: none;
}
.services-item-chauffeur {
	margin-right: 50px;
}
.services-item-chauffeur-info {
	margin-left: -30px;
}
.services-item-chauffeur-price {
	position: absolute;
	right: -80px;
	top: 0;
}
@media (max-width: 1023.98px) {
	.services-item-chauffeur-price {
		position: static;
		right: 0;
		margin-left: -30px;
	}
}
.m-checkout .services-item-chauffeur-price {
	position: static;
	right: 0;
	margin-left: -30px;
}

.opposite-service-button {
	margin-top: 45px;
}

.order-billing {
	margin-bottom: 26px;
}
.order-billing-title {
	color: #9f9593;
}
.order-billing-non-adjusted-price {
	margin-right: 0;
}

.order-summary {
	background: #f2f1f1;
	padding: 0;
}
.order-summary.m-checkout {
	background: none;
	padding: 20px 22px;
}
.order-summary-in {
	padding: 30px 30px 5px;
}
@media (min-width: 1024px) {
	.order-summary-in {
		padding: 36px 36px 11px;
	}
}
@media (min-width: 1024px) and (hover: hover) and (pointer: fine) {
	.order-summary-in {
		position: sticky;
		top: 90px;
	}
}
.order-summary-header {
	align-items: baseline;
	display: flex;
	justify-content: space-between;
	margin-bottom: 22px;
}
.order-summary-title {
	font-family: "DINNextLTPro", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	font-size: 22px;
	margin-bottom: 25px;
}
.order-summary-edit-cart {
	color: #4a4a4a;
	text-decoration: underline;
}
.order-summary-row {
	color: #4a4a4a;
	display: flex;
	font-size: 16px;
	justify-content: space-between;
}
.order-summary-row.hide-order-discount {
	display: none;
}
.order-summary-subtotal {
	color: #2d2423;
	font-weight: 500;
}
.order-summary-total {
	align-items: baseline;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 25px;
}
.order-summary-total-label {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.4;
}
.order-summary-total-label span {
	color: #766a65;
	font-size: 12px;
}
.order-summary-total-value {
	font-size: 22px;
	white-space: nowrap;
}
.order-summary-total .tooltip {
	text-align: justify;
}
.order-summary-discount-label {
	display: inline-block;
	margin-right: 8px;
}
.order-summary-discount-label.m-highlighted {
	color: #329b32;
	font-size: 12px;
	font-weight: 500;
}
.order-summary-discount-label.coupon-text {
	color: #766a65;
	font-size: 12px;
	font-weight: 500;
}
.order-summary-discount-value {
	margin-left: 10px;
	white-space: nowrap;
}
.order-summary-coupon {
	display: inline-block;
	margin-bottom: 10px;
}
.order-summary-coupon-block {
	margin-bottom: 15px;
}
.order-summary-coupon-code {
	margin-bottom: 25px;
}
.order-summary-coupon-applied {
	background: rgba(0, 121, 52, 0.1);
	border-radius: 5px;
	font-size: 13px;
	margin-bottom: 10px;
	padding: 11px 5px;
}
.order-summary-coupon-applied span {
	font-weight: 500;
}
.order-summary-currency-description {
	color: #766a65;
	margin-bottom: 24px;
	margin-top: -14px;
	text-align: center;
}
.order-summary-buttons {
	margin-bottom: 25px;
}
@media (max-width: 767.98px) {
	.order-summary-buttons {
		background: #f2f1f1;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0;
		padding: 15px 30px;
		z-index: 2;
	}
}
.order-summary-approaching-discounts {
	margin-bottom: 25px;
}
.order-summary .m-muted {
	color: #9f9593;
}
.order-summary .order-discount-promo-notice {
	color: #329b32;
	font-size: 12px;
	margin: 0 5px;
	white-space: pre-wrap;
}
@media (min-width: 1024px) {
	.order-summary .order-discount-promo-notice {
		display: block;
		margin: 5px 0 0;
	}
}
.order-summary .order-discount-total {
	white-space: nowrap;
}

.order-details .card-line-item-name {
	color: #2d2423;
	font-weight: 700;
}
.order-details .services-title {
	display: none;
}
.order-details .services-item {
	padding: 14px 0;
}
.order-details .services-item:first-child {
	border-top: 0;
	padding-top: 0;
}
.order-details .services-item:last-child {
	border-bottom: 1px solid #dee2e6;
	margin-bottom: 20px;
}
.order-details .services-item-brand {
	display: none;
}
.order-details .services-item-name {
	display: block;
	margin-bottom: 12px;
	text-decoration: none;
}
.order-details .services-item-passengers {
	margin-bottom: 16px;
}
.order-details .services-item-price {
	display: none;
}
.order-details .services-item-price.m-bottom {
	display: block;
}
.order-details .services-item-booking {
	font-size: 14px;
}
.order-details .services-item-booking-label {
	color: #2d2423;
	font-weight: 700;
}
.order-details .services-item-service {
	display: none;
}
.order-details .services-item-additional-services {
	display: none;
}
.order-details .order-billing {
	border-bottom: 1px solid #dee2e6;
	margin-bottom: 16px;
	padding-bottom: 16px;
}
.order-details .order-billing-title {
	color: #2d2423;
	font-weight: 700;
}
.order-details .order-billing-price {
	font-weight: 700;
	margin-left: 0;
}
.order-details .order-billing-non-adjusted-price {
	font-size: 16px;
	margin-right: 0;
}