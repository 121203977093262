@import '../../components/services';
@import '../../components/order-billing';
@import '../../components/order-summary';

.order-details {
    .card-line-item-name {
        color: color('base-0');
        font-weight: $font-weight-bold;
    }

    .services {
        &-title {
            display: none;
        }

        &-item {
            padding: 14px 0;

            &:first-child {
                border-top: 0;
                padding-top: 0;
            }

            &:last-child {
                border-bottom: 1px solid $border-color;
                margin-bottom: 20px;
            }
        }

        &-item-brand {
            display: none;
        }

        &-item-name {
            display: block;
            margin-bottom: 12px;
            text-decoration: none;
        }

        &-item-passengers {
            margin-bottom: 16px;
        }

        &-item-price {
            display: none;

            &.m-bottom {
                display: block;
            }
        }

        &-item-booking {
            font-size: $body-font-size;
        }

        &-item-booking-label {
            color: color('base-0');
            font-weight: $font-weight-bold;
        }

        &-item-service {
            display: none;
        }

        &-item-additional-services {
            display: none;
        }
    }

    .order-billing {
        border-bottom: 1px solid $border-color;
        margin-bottom: 16px;
        padding-bottom: 16px;

        &-title {
            color: color('base-0');
            font-weight: $font-weight-bold;
        }

        &-price {
            font-weight: $font-weight-bold;
            margin-left: 0;
        }

        &-non-adjusted-price {
            font-size: 16px;
            margin-right: 0;
        }
    }
}
