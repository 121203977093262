$order-summary-bg-color: color('base-6') !default;
$order-summary-tax-color: color('base-5') !default;
$order-summary-muted-color: color('base-4') !default;
$order-summary-highlight-color: #4a4a4a !default;
$order-summary-coupon-bg-color: #007934 !default;

.order-summary {
    background: $order-summary-bg-color;
    padding: 0;

    &.m-checkout {
        background: none;
        padding: 20px 22px;
    }

    &-in {
        padding: 30px 30px 5px;

        @include media-breakpoint-up(lg) {
            padding: 36px 36px 11px;

            @media (hover: hover) and (pointer: fine) {
                position: sticky;
                top: 90px;
            }
        }
    }

    &-header {
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        margin-bottom: 22px;
    }

    &-title {
        font-family: $font-primary;
        font-size: 22px;
        margin-bottom: 25px;
    }

    &-edit-cart {
        color: $order-summary-highlight-color;
        text-decoration: underline;
    }

    &-row {
        color: $order-summary-highlight-color;
        display: flex;
        font-size: 16px;
        justify-content: space-between;

        &.hide-order-discount {
            display: none;
        }
    }

    &-subtotal {
        color: color('base-0');
        font-weight: $font-weight-medium;
    }

    &-total {
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 25px;

        &-label {
            font-size: 18px;
            font-weight: $font-weight-medium;
            line-height: $line-height-base;

            span {
                color: $order-summary-tax-color;
                font-size: 12px;
            }
        }

        &-value {
            font-size: 22px;
            white-space: nowrap;
        }

        .tooltip {
            text-align: justify;
        }
    }

    &-discount {
        &-label {
            display: inline-block;
            margin-right: 8px;

            &.m-highlighted {
                color: $green;
                font-size: 12px;
                font-weight: $font-weight-medium;
            }

            &.coupon-text {
                color: $order-summary-tax-color;
                font-size: 12px;
                font-weight: $font-weight-medium;
            }
        }

        &-value {
            margin-left: 10px;
            white-space: nowrap;
        }
    }

    &-coupon {
        display: inline-block;
        margin-bottom: 10px;

        &-block {
            margin-bottom: 15px;
        }

        &-code {
            margin-bottom: 25px;
        }

        &-applied {
            background: rgba($order-summary-coupon-bg-color, 0.1);
            border-radius: $border-radius;
            font-size: 13px;
            margin-bottom: 10px;
            padding: 11px 5px;

            span {
                font-weight: $font-weight-medium;
            }
        }
    }

    &-currency-description {
        color: color('base-5');
        margin-bottom: 24px;
        margin-top: -14px;
        text-align: center;
    }

    &-buttons {
        margin-bottom: 25px;

        @include media-breakpoint-down(sm) {
            background: $order-summary-bg-color;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 15px 30px;
            z-index: z('above');
        }
    }

    &-approaching-discounts {
        margin-bottom: 25px;
    }

    .m-muted {
        color: $order-summary-muted-color;
    }

    .order-discount-promo-notice {
        color: $green;
        font-size: 12px;
        margin: 0 5px;
        white-space: pre-wrap;

        @include media-breakpoint-up(lg) {
            display: block;
            margin: 5px 0 0;
        }
    }

    .order-discount-total {
        white-space: nowrap;
    }
}
