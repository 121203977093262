.ma-container {
    padding-top: 50px;
    padding-bottom: 60px;

    @include media-breakpoint-up(md) {
        padding-top: 20px;
        padding-bottom: 100px;
    }

    .track-order-header {
        color: color('base-0');
    }

    .card-body-positioning {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
    }

    .breadcrumbs {
        margin-bottom: 0;
    }
}

.ma-download-data {
    @media (pointer: coarse) {
        display: none;
    }
}

.login-page {
    .tabs-nav-list {
        margin-bottom: 20px;
    }

    .create-account-button {
        + .content-asset {
            margin-top: 20px;
        }
    }
}

